import React from 'react';
import styled, {useTheme} from "styled-components";
import EventPlayerList from "./EventPlayerList";
import EventChart from "./EventChart";
import EventInfo from "./EventInfo";
import useEventAPI from "../../common/hooks/useEventAPI";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const Wrapper = styled.div`
`;
const BannerWrapper = styled.div`
    cursor: pointer;
`;
const EventContent = () => {
    const theme = useTheme();
    const {eventQuery} = useEventAPI();
    const currentLang = useSelector((state) => state.translation.lang);

    const getBannerName = () => {
        if(currentLang !== 'KOR' && currentLang !== 'ja') {
            return 'en'
        }
        return currentLang
    }
    return (
        <Wrapper>
            <BannerWrapper onClick={() => window.open('https://www.deeplol.gg/multi/KR/%EB%9D%BC%EC%9D%B4%EC%A7%84%23aaa%2CNinja%20of%20Ninjas%23Ninja%2C%EC%9E%AC%EC%8A%A4%ED%8D%BC%23KR2%2C%EC%A6%9D%EC%98%81%ED%8E%AD%EA%B7%84%230323')}>
                <img src={`images/event/banner_gemagema_${getBannerName()}.svg`} width={1080} height={52} alt={''}/>
            </BannerWrapper>
            <GreenBox/>
            <EventPlayerList data={eventQuery.data.playerArray} room={eventQuery.data.room}/>
            <EventChart list={eventQuery.data.playerArray}/>
            <EventInfo list={eventQuery.data.playerArray} aiList={eventQuery.data.aiScoreArray}/>
        </Wrapper>
    );
};


const GreenBoxWrapper = styled.div`
  margin: 10px 0;
  border: 2px solid ${props => props.theme.color.green_100};
  background-color: ${props => props.theme.color.green_200};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 96px;
  color: ${props => props.theme.color.green_100};
  font-weight: bold;
  font-size: 20px;
`;

const GreenBox = () => {
    const {t} = useTranslation()
    return (
        <GreenBoxWrapper>
            {t('gemagema.banner')}
        </GreenBoxWrapper>
    )
}
export default EventContent;