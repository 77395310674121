import {useSuspenseQuery} from "@tanstack/react-query";
import axios from "axios";

const setEventName = (name) => {
    switch (name) {
        case 'たぬき忍者':
            return "TanukiNinja"
        case 'しゃるる':
            return "Syaruru"
        case 'raizin':
            return "Raizin"
        case 'jasper':
            return "Jasper"
        case 'げまげま':
            return "ROOM"
        default:
            return ""
    }
}

const useEventAPI = () => {
    const query = useSuspenseQuery({
        queryKey: ['jpEvent'],
        queryFn: async () => {
            const [challenge, broadcast] = await Promise.all([axios.get(`${process.env.REACT_APP_CACHE_API}summoner/strm_pro_challenge`), axios.get(`${process.env.REACT_APP_CACHE_API}summoner/strm_pro_broadcast`)]);
            const combined = broadcast.data.broadcast_info.map((data) => {
                const find = challenge.data.challenge_list.find((item) => data.name.toLowerCase() === item.name.toLowerCase());
                if (find) {
                    return {
                        ...data,
                        ...find,
                    }
                }
                return {
                    ...data,
                }
            });
            const tanukiNinja = {...combined.find((data) => data.name === 'たぬき忍者'),playerName: 'TanukiNinja',lane: 'middle'};
            const syaruru = {...combined.find((data) => data.name === 'しゃるる'),playerName: 'Syaruru',lane:'jungle'};
            const raizin = {...combined.find((data) => data.name === 'raizin'),playerName: 'Raizin',lane: 'top'};
            const jasper = {...combined.find((data) => data.name === 'jasper'),playerName: 'Jasper',lane: 'bot'}
            const room = {...combined.find((data) => data.name === 'げまげま'),playerName: 'ROOM'}



            const aiScoreArray = challenge.data.ai_score_list.map((data) => {
                const findPlayer = combined.find((item) => data.puu_id === item.puu_id);
                if(findPlayer) {
                    return {
                        ...data,
                        playerName: setEventName(findPlayer.name)
                    }
                }
                return {
                    ...data,
                    playerName:""
                }
            })

            const playerObj = {
                tanukiNinja,syaruru,raizin,jasper
            }


            return {
                challenge: challenge.data.challenge_list,
                broadcast: broadcast.data.broadcast_info,
                combined: combined,
                playerObj:playerObj,
                playerArray: [playerObj.raizin,playerObj.syaruru,playerObj.tanukiNinja,playerObj.jasper],
                aiScoreArray: aiScoreArray,
                room:room
            }
        },
        retry: 0,
        refetchOnWindowFocus: false,
    })
    return {
        eventQuery: query,
    }
};

export default useEventAPI;