import React, {useMemo} from 'react';
import styled from "styled-components";
import {FlexBox} from "../ui/styled";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Graph = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 14px;
  border-radius: 100px;
  background-color: ${props => props.theme.color.gray_100};
`;
const Filled = styled.div`
  height: 14px;
  border-radius: 100px;
  width: ${props => props.percent}%;
  background-color: ${props => props.theme.color.green_100};
  position: relative;
`;
const CrownBox = styled.div`
  margin-right: 5px;
`;
const SummonerNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
`;
const Name = styled.div`
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
`;
const Percent = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${props => props.theme.color.dark_100};
`;
const EventDamageGraph = ({bgColor, barColor, dmg, total, name, rank, isTime}) => {
    const {t} = useTranslation()
    const calcFilled = useMemo(() => {
        const result = (dmg / total * 100).toFixed(1);
        if (result >= 100) {
            return 100
        }
        return result;
    }, [total, dmg,]);

    const calcValue = useMemo(() => {
       if(isTime) {
           return dmg
       } else {
           return calcFilled
       }

    },[calcFilled])

    return (
        <Wrapper>
            <FlexBox justifyContent={'space-between'} margin={'0 0 4px 0'}>
                <SummonerNameWrapper>
                    {rank === 1 &&
                        <CrownBox>
                            <img src="/images/ico_best_player01.svg" alt={''} width={20} height={20}/>
                        </CrownBox>}
                    <Name>
                        {name}
                    </Name>
                </SummonerNameWrapper>
                <Percent>
                    {calcValue}{isTime ? t('gemagema.liveHour') : '%'}
                </Percent>
            </FlexBox>
            <Graph bgColor={bgColor}>
                <Filled percent={calcFilled} color={barColor}/>
            </Graph>
        </Wrapper>
    );
};

export default EventDamageGraph;