import React from 'react';
import styled, {useTheme} from "styled-components";
import {ImageLane} from "../common";
import {romanNumberReverseConverter} from "../../function";
import EventIcon from "./EventIcon";

const Wrapper = styled.div`
  margin-top: 29px;
`;
const ChartBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;
`;
const TierBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 171px;
`;
const Tier = styled.div`
  position: absolute;
  font-size: 14px;
  top: -19px;
  font-weight: 500;
`;

const ExtraTier = styled(Tier)`
  font-weight: 900;
`;

const Chart = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 180px;
  height: 100%;
`;
const Gauge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 3px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  position: absolute;
  width: ${props => props.width}px;
  height: 40px;
  left: 180px;
  overflow: hidden;
  background-color: ${props => {
    let color = 'rgba(29, 167, 148, 0.5)'
    if (props.tier === 'MASTER') {
      color = 'rgba(134, 61, 161, 0.5)'
    } else if (props.tier === 'DIAMOND') {
      color = 'rgba(40, 152, 201, 0.5)';
    } else if (props.tier === 'EMERALD') {
      color = 'rgba(18, 195, 201, 0.5)';
    }
    return color;
  }};

  .playerImage {
    border-radius: 100%;
    border: 4px solid ${props => {
      let color = 'rgba(29, 167, 148, 1)'
      if (props.tier === 'MASTER') {
        color = 'rgba(134, 61, 161, 1)'
      } else if (props.tier === 'DIAMOND') {
        color = 'rgba(40, 152, 201, 1)';
      } else if (props.tier === 'EMERALD') {
        color = 'rgba(18, 195, 201, 1)';
      }
      return color;
    }};
  }
`;
const PlayerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;

  &.light + &.light {
    border-top: 1px solid ${props => props.theme.color.default_border_color};
  }

  &.dark + &.dark {
    border-top: 1px solid ${props => props.theme.color.default_border_color};
  }
`;

const LastTier = styled(ExtraTier)`
  right: 0;
`;
const EventChart = ({list}) => {

    const tierGaugeCalc = ({tier, division, lp}) => {
        const WIDTH_VALUE = 75;
        const WRAPPER_VALUE = 300;
        const currentDivision = romanNumberReverseConverter(division);
        const tierArray = ['PLATINUM', 'EMERALD', 'DIAMOND'];
        if (tier === 'MASTER' || tier === 'GRANDMASTER' || tier === 'CHALLENGER') {
            return 900
        }

        const tierIndex = tierArray.findIndex((data) => data === tier);
        const tierMultiple = WRAPPER_VALUE * tierIndex;
        const divisionMultiple = WIDTH_VALUE * currentDivision
        const lpMultiple = lp * 0.75

        return tierMultiple + divisionMultiple + lpMultiple
    }
    const getTier = ({tier}) => {
        try {
            const splitTier = tier.split(' ');
            const division = splitTier[1];
            const tierValue = splitTier[0];
            return {tier: tierValue, division}
        } catch (e) {
            return {
                tier: 'unranked',
                division: ""
            }
        }
    }
    return (
        <Wrapper>
            <ChartBox>
                {list.map((data) => {
                    const currentTier = getTier({tier: data.tier})
                    return (
                        <PlayerBox
                            key={data.summoner_id} name={data.playerName}
                            gauge={tierGaugeCalc({
                                tier: currentTier.tier,
                                division: currentTier.division,
                                lp: data.lp
                            })}
                            tier={currentTier.tier}
                            lane={data.lane}
                            riot_id={data.riot_id}
                            riot_tag={data.riot_tag}
                            puuId={data.puu_id}
                        />
                    )
                })}
                <Chart>
                    <DashedLine tierArray={['P4', 'P3', 'P2', 'P1']} tier={'platinum'}/>
                    <DashedLine tierArray={['E4', 'E3', 'E2', 'E1']} tier={'emerald'}/>
                    <DashedLine tierArray={['D4', 'D3', 'D2', 'D1']} tier={'diamond'}/>
                    <LastTier className={'tier_color master'}>M</LastTier>
                </Chart>
            </ChartBox>
        </Wrapper>
    )
        ;
};

const DashedLineWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  border-left: 1px solid ${props => props.theme.color.default_border_color};
  width: 300px;
  height: 100%;
`;
const Dash = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1px;
  margin-right: 74px;
  border-right: 1px dashed ${props => props.theme.color.default_border_color};
`;
const EmptyBox = styled(Dash)`
  border-right: 0;
`;
const DashedLine = ({tierArray, tier}) => {
    return (
        <DashedLineWrapper>
            {tierArray.map((data, index) => {
                if (index === 0) {
                    return (
                        <EmptyBox key={index}>
                            <ExtraTier className={`tier_color ${tier}`}>
                                {data}
                            </ExtraTier>
                        </EmptyBox>
                    )
                }
                return (
                    <Dash key={index}>
                        <Tier className={`tier_color ${tier}`}>
                            {data}
                        </Tier>
                    </Dash>
                )
            })}
        </DashedLineWrapper>
    )
}
const PlayerBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  width: 180px;
  height: 60px;

  .imgLane {
    width: 20px;
    height: 20px;
  }


`;
const PlayerName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.color.dark_100};
  margin-left: 10px;
`;
const PlayerBox = ({name, gauge, tier, lane, riot_id, riot_tag, puuId}) => {
    const theme = useTheme();
    return (
        <PlayerWrapper className={theme.color.theme}>
            <PlayerBoxWrapper>
                <ImageLane lane={lane} color={'dark'}/>
                <PlayerName>{name}</PlayerName>
                <Gauge tier={tier} width={gauge}>
                    <EventIcon name={name} height={36} width={36} riot_tag={riot_tag} riot_id={riot_id} puuId={puuId}/>
                </Gauge>
            </PlayerBoxWrapper>
        </PlayerWrapper>
    )

}

export default EventChart;