import React, {useEffect} from 'react';
import useQuery from "../../common/hooks/useQuery";
import {useHistory, useLocation} from "react-router-dom";

const ToMobile = () => {
    const {search} = useLocation();
    useQuery();
    localStorage.getItem('pc');
    useHistory();
    const pathname = window.location.pathname;

    const isURLSummoner = () => {
        const reg = /summoner/g;
        return reg.test(pathname);
    }
    const isURLPro = () => {
        const reg = /\/(pro|strm)\//g;
        return reg.test(pathname);
    }
    const isURLDaily = () => {
        const reg = /daily\/ai/g;
        return reg.test(pathname);
    }
    const isURLDetail = () => {
        const championReg = /champions/i;
        if (championReg.test(pathname)) {
            const championCategory = /build|mastery|history/i;
            return championCategory.test(pathname);
        }
        return false;
    }
    const navigateToPro = () => {
        const splited = pathname.split('/');
        const params = `/${splited[1]}?name=${splited[2]}`;
        if (splited[2]) {
            window.location.href = 'https://m.deeplol.gg' + params;
        } else {
            window.location.href = 'https://m.deeplol.gg'
        }
    }
    const navigateToSummoner = () => {
        const splited = pathname.split('/');
        const params = `/summoner?userName=${splited[3]}&region=${splited[2] ? splited[2] : 'KR'}`;
        if (splited[2]) {
            window.location.href = 'https://m.deeplol.gg' + params;
        } else {
            window.location.href = 'https://m.deeplol.gg'
        }
    }
    const navigateToChampion = () => {
        const splited = pathname.split('/');
        const ORIGIN_URL = new URL(window.location);
        const server = ORIGIN_URL.searchParams.get('region') || "KR";
        const url = new URL('https://m.deeplol.gg/champions/detail');
        url.searchParams.set('championName', splited[2]);
        url.searchParams.set('tabs', splited[3]);
        if (splited[3] === 'build') {
            // https://m.deeplol.gg/champions/detail?championName=gangplank&version=13.24&tier=Master%2B&lane=top&tabs=build
            const tier = ORIGIN_URL.searchParams.get('tier') || 'Emerald'
            const version = ORIGIN_URL.searchParams.get('version');
            url.searchParams.set('tier', encodeURI(tier.trim() + "+"));
            url.searchParams.set('lane', splited[4]);
            if (version) {
                url.searchParams.set('version', version);
            }
            window.location.href = url.href;
        } else if (splited[3] === 'mastery') {
            // ['', 'champions', 'gangplank', 'mastery', 'all']
            url.searchParams.set('server', server);
            url.searchParams.set('lane', splited[4]);
            window.location.href = url.href;
        } else {
            window.location.href = url.href;
        }

    }


    // https://m.deeplol.gg/daily/ai?server=kr&tier=challenger&queue=rank
    const navigateToDaily = () => {
        const splited = pathname.split('/');
        const queryString = new URLSearchParams(search);
        const date = queryString.get('date');
        const url = new URL('https://m.deeplol.gg/daily/ai')
        if (splited[5] === 'all') {
            splited[5] = 'challenger'
        }
        url.searchParams.set('server', splited[3]);
        url.searchParams.set('queue', splited[4]);

        if (!splited[5]) {
            url.searchParams.set('tier', 'all');
        } else {
            url.searchParams.set('tier', splited[5]);
        }

        if (date) {
            url.searchParams.set('date', date);
        }

        if (splited[3]) {
            window.location.href = url.href;
        } else {
            window.location.href = 'https://m.deeplol.gg'
        }
    }
    useEffect(() => {
        if (isURLDaily()) {
            navigateToDaily();
        } else if (isURLPro()) {
            navigateToPro();
        } else if (isURLSummoner()) {
            navigateToSummoner();
        } else if (isURLDetail()) {
            navigateToChampion();
        } else {
            window.location.href = `https://m.deeplol.gg/`;
        }
    }, []);

    return <></>;
};

export default ToMobile;
