import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useTitle} from '../../function';
import {HomeContainer} from '../../styled/HomeStyle';
import {Header} from '../common';
import {useSelector} from "react-redux";
import styled, {useTheme} from "styled-components";
import ServerStatusErrorbox from "../common/Error/StatusError";
import NewSearch from "../common/NewSearch";
import {home_ad_conetne} from "../../ad/bidrich";
import useAdHooks from "../../common/hooks/useAdHooks";
import UseSetCanonical from "../../common/hooks/useSetCanonical";
import HomeNoticeBoard from "./HomeNoticeBoard/HomeNoticeBoard";
import HomeNotice from "./HomeNotice";


const AdSection = styled.div`
  width: 970px;
  height: 250px;
  margin-bottom: 60px;
`;

const BannerWrapper = styled.div`
  margin: 70px 0 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled(ColumnBox)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const BoardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

// const testTierList = ['challenger','grandmaster','master','diamond','emerald','platinum','gold','silver','bronze','iron','unranked'];

const Home = () => {
    UseSetCanonical();

    const {t, i18n} = useTranslation();
    const titleUpdate = useTitle('Loading...');
    const notice = useSelector((state) => state?.imageStore.notice);
    const country = useSelector((state) => state?.imageStore.country);
    // const argumentsInfo = useSelector((state) => state?.imageStore.argumentsInfo);
    useAdHooks(home_ad_conetne);
    const theme = useTheme();
    const currentLang = useSelector((state) => state.translation.lang)

    const [openFeedback, setOpenFeedback] = useState(false);
    setTimeout(() => titleUpdate(t('header.title')), 500);


    // if(true) {
    //     return (
    //         <Wrapper>
    //             {argumentsInfo.map((data) => {
    //                 return (
    //                     <FlexBox>
    //                         <ArgumentImage imgId={data.id} key={data.id} width={48} height={48}/>
    //                     </FlexBox>
    //
    //                 )
    //             })}
    //         </Wrapper>
    //     )
    // }


    const onClickRiotMore = () => {
        if(currentLang === 'KOR') {
            return window.open('https://www.leagueoflegends.com/ko-kr/news/tags/patch-notes/')
        }
        return window.open('https://www.leagueoflegends.com/en-us/news/tags/patch-notes/')

    }


    const onClickDeeplolMore = () => {
        if(currentLang === 'KOR') {
            return window.open('https://deeplol-notice.notion.site/2aa360ac56404feba05e3ee4643ef758?v=2b9f8c6b16a4449d91b64c931a676717')
        }
        return window.open('https://deeplol-notice.notion.site/22ccbbf31911418f90d1571fd3bfa745?v=e439086776eb4a5ab7a9d3b939c61e4d')
    }


    return (
        <Wrapper country={country}>
            {/*Rich*/}
            <ColumnBox>
                <Header
                    isHome={true}
                    setOpenView={setOpenFeedback}
                    openView={openFeedback}
                />
                <ServerStatusErrorbox/>
            </ColumnBox>
            <HomeContainer>
                <div className="home" id={'home_content_wrapper'}>
                    <h2>
                            <img src={`${theme.icon.path}/event/logo_gemagema_main.svg`} alt="DEEPLOL"/>
                            {/*<img src={`${theme.icon.path}/ico_logo_w.png`} alt="DEEPLOL"/>*/}
                    </h2>
                    <BannerWrapper>
                        <HomeNotice/>
                        <NewSearch home/>
                    </BannerWrapper>
                    {/*<HomeVideoBannerWrapper/>*/}
                    <AdSection class={''} id={'home_content_bottom_ad_box'} data-ad={'billboard'}>

                    </AdSection>
                    <BoardWrapper>
                        <HomeNoticeBoard title={'RIOT'}
                                         list={notice?.riot_notice_list}
                                         onClickMore={onClickRiotMore}
                        />
                        <HomeNoticeBoard title={'DEEPLOL'}
                                         list={notice?.deeplol_notice_list}
                                         onClickMore={onClickDeeplolMore}
                        />
                    </BoardWrapper>
                </div>
            </HomeContainer>
        </Wrapper>
    );

};

export default Home;
