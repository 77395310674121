import React from 'react';
import IngameNotPlaying from "./notPlaying";
import {Loading} from "../../common";
import useIngameDispatch from "../../../common/hooks/useIngameDispatch";
import styled from "styled-components";
import IngameOverview from "./overview";
import IngamePlayerTable from "./playerTable";
import IngameTeamChart from "./ingameTeamChart";

const ErrorWrapper = styled.div`
  height: 360px;
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.default_border_color};
`;

const IngameProvider = ({children, region}) => {
    const {
        ingameStatus,
        getIngameCheckLoading,
        getIngameInfoSummonerLoading,
        ingame
    } = useIngameDispatch(region);


    if (getIngameCheckLoading && ingame.part.length === 0) {
        return <ErrorWrapper><Loading/></ErrorWrapper>
    }

    if (getIngameInfoSummonerLoading) {
        return <ErrorWrapper><Loading/></ErrorWrapper>
    }

    if (!ingameStatus) {
        return (<IngameNotPlaying/>)
    }


    if (ingame?.part?.length < 1) return (<ErrorWrapper><Loading/></ErrorWrapper>)

    if (!children) return <div>Childrens are not exist check your code</div>

    return (<div>
        {children}
    </div>);
};

IngameProvider.Overview = IngameOverview;
IngameProvider.PlayerTable = IngamePlayerTable;
IngameProvider.TeamChart = IngameTeamChart;


export default IngameProvider;
