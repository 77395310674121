import React from 'react';
import ChampionDetailRuneImages from "../ChampionDetailRuneImages";

const DetailMapRune = ({runes = [], selectedRune = [], borderColor, width = 34, height = 34, imageBorder}) => {

    return (
        <>
            {Array.isArray(runes) && runes.map((data, index) => {
                return (
                    <ChampionDetailRuneImages
                        imageBorder={imageBorder}
                        disalbed={Array.isArray(selectedRune) && !selectedRune.includes(data.id)}
                        className={"detailRunes"}
                        key={data.id + "runes"}
                        width={width}
                        height={height}
                        image={data.icon}
                        borderColor={borderColor}
                        tooltip={`<span style="color : rgb(23, 199, 145); font-weight: 500">${data.name}</span>\n${data.longDesc ? data.longDesc : data.shortDesc}`}
                    />
                )
            })}

        </>
    );
};

export default DetailMapRune;
