import React, {useCallback} from 'react';
import styled from "styled-components";

const GuageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  border-radius: 3px;
  overflow: hidden;
`;
const Winguage = styled.div`
  display: flex;
  background-color: ${props => props.theme.color.blue_100};
  flex: ${props => props.widthPercent};
  flex-shrink: 1;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const EmptyGauge = styled.div`
  display: flex;
  background-color: #8997AB;
  flex: 1;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`

const LoseGuage = styled.div`
  display: flex;
  flex: ${props => props.widthPercent};
  flex-shrink: 1;
  background-color: ${props => props.theme.color.red_100};
  justify-content: flex-end;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const WinLoseText = styled.span`
  font-size: 12px;
  color: #fff;
  padding: 4px 5px;
`;

const WinRateBar = ({win, lose}) => {

    const calcGuage = useCallback((value) => {
        return value / (win + lose);
    }, [win, lose]);


    if (win === 0 && lose === 0) {
        return (
            <GuageWrapper>
                <EmptyGauge>
                    <WinLoseText>
                        0
                    </WinLoseText>
                </EmptyGauge>
            </GuageWrapper>
        )
    }


    return (
        <>
            <GuageWrapper>
                {win !== 0 &&
                    <Winguage widthPercent={calcGuage(win)}>
                        <WinLoseText>
                            {win}
                        </WinLoseText>
                    </Winguage>
                }
                {lose !== 0 &&
                    <LoseGuage widthPercent={calcGuage(lose)}>
                        <WinLoseText>
                            {lose}
                        </WinLoseText>
                    </LoseGuage>
                }
            </GuageWrapper>
        </>
    );
};

export default WinRateBar;
