import React from 'react';
import useAdHooks from "../../common/hooks/useAdHooks";

const AdComponent = ({children,playwire}) => {
    useAdHooks(playwire)
    return (
        <>
            {children}
        </>
    );
};

export default AdComponent;