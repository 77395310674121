import styled from "styled-components";
import {Header} from "../common";
import ChampionsDetailContent from "./ChampionsDetailContent";
import {ErrorBoundary} from "react-error-boundary";
import {useParams} from "react-router-dom";
import CenterBox from "../common/CenterBox";
import {champion_detail_content} from "../../ad/bidrich";
import AdComponent from "../ad/AdComponent";

const Wrapper = styled.div`
`;


const ChampionsDetail = () => {
    const {champName} = useParams();

    return (
        <Wrapper>
            <Header/>
            <ErrorBoundary fallback={<></>}>
                <AdComponent playwire={champion_detail_content}>
                    <CenterBox flexD={'column'} disableExtraHeader={true}>
                        <ChampionsDetailContent champName={champName} key={champName}/>
                    </CenterBox>
                </AdComponent>
            </ErrorBoundary>
        </Wrapper>
    );
};

export default ChampionsDetail;
