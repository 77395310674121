import {useLocation} from "react-router-dom";
import React, {useState} from "react";
import _ from "lodash";

const KoreanAd = () => {
    const location = useLocation();

    const setAd = (value) => {
        localStorage.setItem('summonerBanner', String(value))
        setAdState(String(value));
    }

    const [adState,setAdState] = useState(() => {
        const random = _.random(1, 10);
        // setAd(random)
        return String(random)
    });





    // useEffect(() => {
    //     if (adState === '1') {
    //         setAd(2)
    //     } else if (adState === '2') {
    //         setAd(1)
    //     }
    // }, [location]);




    if (['1','2','3','4','5','6','7'].includes(adState)) {
        return (
            <a href={'https://bit.ly/3YpE2uX'} target={'_blank'}>
                <img src={'./images/ad_geng_kr.png'} width={'100%'} height={52} alt={''}
                     className={'add_to_Gen'}/>
            </a>
        )
    } else {
        return (
            <a href={'https://ch.sooplive.co.kr/podone1104/post/138536371'} target={'_blank'}>
                <img src={'./images/podo_kr_title_1.png'} width={'100%'} height={52} alt={''}
                     className={'add_to_podo'}/>
            </a>
        )
    }
}
export default KoreanAd