import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ErrorBoundary} from "react-error-boundary";
import SummonerContent from "../common/SummonerContent";
import {GET_SUMMONER_REQUEST, INIT_SUMMONER} from "../../store/reducer/summoner";
import {searchList} from "../../store/modules/searchList";
import {useTitle} from "../../function";
import {useTranslation} from "react-i18next";
import {krAdStart} from "../../ad/bidrich";
import {Header, Modal} from "../common";
import ServerStatusErrorbox from "../common/Error/StatusError";
import UseRailScrollEvent from "../../common/hooks/useScrollEvent";
import styled from "styled-components";
import {SummonerContainer} from "../../styled/SummonerStyle";

const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;


const StickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;
const RightSIdeStickyBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
`;

const StickyBoxWrapper = styled.div`
  width: 340px;
  padding: 20px;
  position: relative;
`;
const SmallRail = styled.div`
  min-height: 1px;
  margin-bottom: 10px;
`;


const LeftSideWrapper = styled.div`
  width: 300px;
  height: 600px;
`;

const RightSideWrapper = styled.div`
  width: 300px;
  height: 600px;
`;

const TopAdWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 1080px;
`;


const Summoner = () => {
    const modalOpen = useSelector((state) => state?.modal.open);
    const modalData = useSelector((state) => state?.modal.modalData);
    const match = useSelector((state) => state?.modal.match);
    const country = useSelector((state) => state?.imageStore.country);
    const {
        summoner,
        getSummonerDone,
        getSummonerError,
    } = useSelector((state) => state?.summoner);    // useEffect(() => {
    const {t} = useTranslation();
    const titleUpdate = useTitle('Loading...');
    const currentSeason = useSelector((state) => state.imageStore.currentSeason);
    const dispatch = useDispatch();
    const {userName, region, matchId} = useParams();
    const [tempPuuId, setTempPuuId] = useState(JSON.parse(localStorage.getItem("tempPuuId")));


    const currentUserName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.riot_id_name;
        } else {
            return userName;
        }
    }, [summoner, userName]);

    const currentTagName = useMemo(() => {
        if (userName && summoner) {
            return `#${summoner?.summoner_basic_info_dict?.riot_id_tag_line}`
        } else {
            return '#';
        }
    }, [summoner, userName])

    const currentProName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.pro_streamer_info_dict?.name
        } else {
            return '';
        }
    }, [summoner, userName])


    const getData = useCallback(() => {
        if (currentSeason) {
            dispatch({
                type: GET_SUMMONER_REQUEST,
                data: {
                    username: userName,
                    region: region.toUpperCase(),
                    matchId: matchId,
                    puu_id: tempPuuId,
                    currentSeason: currentSeason,
                }
            })
        }
    }, [userName, region, matchId, tempPuuId, currentSeason]);
    useEffect(() => {
        getData();
    }, [userName, matchId, region, currentSeason]);

    useEffect(() => {
        return () => {
            dispatch({
                type: INIT_SUMMONER
            })
        }
    }, []);

    // decodeURI(currentUserName)
    useEffect(() => {
        if (getSummonerDone) {
            dispatch(searchList({...summoner, region: region}, 'add'));

            // window.history.replaceState(null, null, `/summoner/${region}/${currentUserName}`);
            setTempPuuId('');
            krAdStart()
            // if (country === 'KR') {
            //     krAdStart();
            // } else {
            //     playwire_start(summoner_ad_content);
            // }
        }
    }, [getSummonerDone])


    useEffect(() => {
        if (getSummonerError === 404) {
            setTimeout(() => titleUpdate(`${t('header.title')}`), 100);
        }
    }, [getSummonerError]);
    return (
        <>
            <Header
                noticeOff={true}
            />
            <ErrorBoundary fallback={<>Something went wrong...</>}>
                <SummonerContainer id={'summoner_container'}>
                    <ServerStatusErrorbox/>
                    <FlexBox>
                        <LeftRailAd/>
                        <ContentWrapper id={'summoner_content_wrapper'}>
                            <TopAdWrapper id={'summoner_content_top_ad_box'} data-ad="billboard">
                            </TopAdWrapper>
                            <SummonerContent currentUserName={currentUserName}/>
                            <TopAdWrapper id={'summoner_content_bottom_ad_box'}>

                            </TopAdWrapper>
                        </ContentWrapper>
                        <RightRailAd/>
                    </FlexBox>
                </SummonerContainer>
                {modalOpen && <Modal modalData={modalData} open={modalOpen} match={match}/>}
            </ErrorBoundary>
        </>
    );
};
const LeftRailAd = memo(() => {
    const ref = UseRailScrollEvent();
    return (
        <StickyBoxWrapper>
            <StickyBox ref={ref}>
                <SmallRail data-ad={'left-rail-1'}/>
                <LeftSideWrapper  data-ad={"left-rail-2"}/>
            </StickyBox>
        </StickyBoxWrapper>
    )
})


const RightRailAd = memo(() => {
    const ref = UseRailScrollEvent();
    return (
        <StickyBoxWrapper>
            <RightSIdeStickyBox ref={ref}>
                <SmallRail data-ad={'right-rail-1'}/>
                <RightSideWrapper data-ad={'right-rail-2'}/>
            </RightSIdeStickyBox>
        </StickyBoxWrapper>
    )
})
export default Summoner;
