import React from 'react';
import styled from "styled-components";
import OverviewContent from "./OverviewContent";
import {useDispatch} from "react-redux";
import {ErrorBoundary} from "react-error-boundary";
import {useTranslation} from "react-i18next";
import OverviewSortFilter from "./OverviewSortFilter";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 5px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  border-radius: 6px;
`;
styled(ContentWrapper)`
  padding: 0 11px;
  margin-bottom: 8px;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: ${props => props.theme.color.site_main_color};
  border-radius: 6px;
  border: 1px solid ${props => props.theme.color.default_border_color};
`;

const EmptyText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 310px;
  padding: 12px;
  border-radius: 9px;
  color: ${props => props.theme.color.dark_200};
  font-weight: bold;
  font-size: 20px;
`;
function Fallback({error, resetErrorBoundary}) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    );
}

const NewOverview = ({
                         selectedQueue,
                         selectedChampionId,
                         onChangeSelectedQueue,
                         onChangeSelectedChampionId,
                         matchList,
                         empty,
                         queueFilterMatch
                     }) => {
    useDispatch();
    // const [selectedChampId, onChangeSelectedChampId] = useInput(0);
    const {t} = useTranslation()

    // const handleQueueFilter = useCallback((queueNum) => {
    //     onChangeSelectedQueue(queueNum);
    //
    //     dispatch({
    //         type: GET_MATCH_BY_QUEUE_REQUEST,
    //         data: {
    //             champId: selectedChampId,
    //             queueNum: queueNum,
    //             region: region,
    //         }
    //     })
    // }, [selectedChampId, region]);

    if (empty) {
        return (
            <ContentWrapper>
                {/*<QueueFilter onChangeSelected={handleQueueFilter} selected={selectedQueue}/>*/}
                <EmptyBox>
                    <EmptyText>{t("notice.noGame")}</EmptyText>
                </EmptyBox>
            </ContentWrapper>
        )
    }

    return (
        <Wrapper>
            <OverviewSortFilter
                queueFilterMatch={queueFilterMatch}
                match={matchList}
                queue={selectedQueue}
                selectedChampionId={selectedChampionId}
                onChangeQueue={onChangeSelectedQueue}
                onChangeChampionId={onChangeSelectedChampionId}/>

            {/*<QueueFilter*/}
            {/*    onChangeSelected={handleQueueFilter}*/}
            {/*    selected={selectedQueue}*/}
            {/*/>*/}
            <ContentWrapper>
                <ErrorBoundary FallbackComponent={Fallback}>
                    <OverviewContent
                        matches={matchList}
                        queue={selectedQueue}
                    />
                </ErrorBoundary>
            </ContentWrapper>
        </Wrapper>
    );
};

export default NewOverview;
