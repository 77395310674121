import React, {useMemo} from 'react';
import {ImageItem} from "../../../common";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import useItemInfo from "../../../../common/hooks/useItemInfo";
import {BuildImageItem} from "../../../common/ImageSrc";

const ImgBox = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  .imgItem {
    margin-right: 0;
  }

  & + & {
    margin-left: 5px;
  }
`;

const AbsoluteBox = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.8);
  width: 16px;
  height: 16px;
  border: 1px solid #010101;
  line-height: 14px;
`;


const ArrowBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;
const CounterText = styled.span`
  font-size: 12px;
  color: #fff;
`;
const BuildStartItem = ({list,noSubItem}) => {
    const {t} = useTranslation();
    const {isSupItem} = useItemInfo();
    const reduceArray = useMemo(() => {
        if (Array.isArray(list)) {
            const array = list.reduce((sum, cur) => {
                const findIndex = sum.findIndex((findNumber) => Number(findNumber?.itemId) === Number(cur));
                if (findIndex > -1) {
                    sum[findIndex] = {
                        ...sum[findIndex],
                        count: sum[findIndex].count + 1
                    }
                } else {
                    sum.push({
                        itemId: Number(cur),
                        count: 1,
                    })
                }
                return sum;
            }, [])
            return array
        }

        return [];
    }, [list]);

    return (
        <>
            {reduceArray.map((item, index) => {
                const isSup = isSupItem(item.itemId)
                if(isSup && noSubItem) return null
                if(isSup) {
                    return (
                        <ImgBox key={item.itemId + "startItem" + index}>
                            <ArrowBox>
                                <img src={'/images/icon_collapse_arrow.svg'} width={12} height={12}/>
                            </ArrowBox>
                            <BuildImageItem itemId={Number(item.itemId)} width={32} height={32}/>
                            {item?.count && item.count > 1 &&
                                <AbsoluteBox>
                                    <CounterText>{item.count}</CounterText>
                                </AbsoluteBox>}
                        </ImgBox>)
                } else {
                    return (
                        <ImgBox key={item.itemId + "startItem" + index}>
                            <ImageItem itemId={Number(item.itemId)} width={32} height={32}/>
                            {item?.count && item.count > 1 &&
                                <AbsoluteBox>
                                    <CounterText>{item.count}</CounterText>
                                </AbsoluteBox>}
                        </ImgBox>)

                }
            })}
        </>
    );
};

export default BuildStartItem;