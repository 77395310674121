import React, {useMemo} from 'react';
import styled from "styled-components";
import {prevTierAndDivision, romanNumberTrans} from "../../function";
import EventPlayer from "./EventIcon";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const EventPlayerList = ({data,room}) => {
    return (
        <Wrapper>
            {data.map((item) => {
                return (
                    <PlayerCard key={item.summoner_id} lp={item.lp} tier={item.tier} name={item.playerName} live={item.live} riot_tag={item.riot_tag} riot_id={item.riot_id} isRoom={false} puuId={item.puu_id}/>
                )
            })}
            <PlayerCard tier={'UNRANKED'} name={'ROOM'} lp={0} isRoom={true} live={room?.live}/>
        </Wrapper>
    );
};


const CardWrapper = styled.div`
  width: 208px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  background-color: ${props => props.theme.color.site_main_color};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 0 20px 0;

  & + & {
    margin-left: 10px;
  }
`;

const NameText = styled.div`
  color: ${props => props.theme.color.dark_100};
  font-weight: bold;
  font-size: 20px;
  margin: 15px 0 10px 0;
`;

const TierBox = styled.div`
  width: 168px;
  padding: 8px 20px;
  border-radius: 6px;
  background-color: ${props => props.theme.color.white_201};
`;

const DictWrapper = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 6px;
  text-transform: capitalize; 

  &.challenger {
    background-color: #FFD940;
    color: #2660E6;
  }

  &.grandmaster {
    background-color: #EBB4C4;
    color: #B9354A;
  }

  &.master {
    background-color: #D3B3EA;
    color: #8D41A9;
  }

  &.diamond {
    background-color: #A7CDF2;
    color: #0063BB;
  }

  &.platinum {
    background-color: #97CBC4;
    color: #2A8492;
  }

  &.emerald {
    background-color: #7FD6A7;
    color: #21855A;
  }

  &.gold {
    background-color: #DBC798;
    color: #A17716;
  }

  &.silver {
    background-color: #B7BBCB;
    color: #626B8A;
  }

  &.bronze {
    background-color: #C0AA9B;
    color: #815639;
  }

  &.iron {
    background-color: #A7A097;
    color: #514330;
  }

  &.unranked {
    color: #7F7F7F;
    background-color: #D1D4DB;
  }
`;
const LPText = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  color: ${props => props.theme.color.dark_100};
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  line-height: 24px;
`;

const PlayerCard = ({name, tier, lp,live,riot_id,riot_tag,puuId,isRoom}) => {

    const tierMeme = useMemo(() => {
        try {
            const splitTier = tier.split(' ');
            const division = romanNumberTrans(splitTier[1]);
            const tierItem = splitTier[0];
            return {tier:tierItem, division}
        } catch (e) {
            console.log(e)
            return {
                tier: 'UNRANKED',
                division: ""
            }
        }
    }, []);

    return (
        <CardWrapper>
            <PlayerCircle name={name} live={live} riot_tag={riot_tag} riot_id={riot_id} isRoom={isRoom} puuId={puuId}/>
            <NameText>
                {name}
            </NameText>
            <TierBox>
                <DictWrapper className={tierMeme.tier.toLowerCase()}>
                    {prevTierAndDivision(tierMeme.tier.toLowerCase(),tierMeme.division)}
                </DictWrapper>
                <LPText>
                    {lp}LP
                </LPText>
            </TierBox>
        </CardWrapper>
    )
}


////////////////////////
const Circle = styled.div`
  cursor: pointer;
  border-radius: 100%;
  outline: 4px solid ${props => {
      let outline = 'transparent';
      if(props.live === 1) {
          outline = '#EB5B5B';
      }
      return outline
  }};
  outline-offset: 2px;
  width: 100px;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
`;
const LiveAbsolute = styled.div`
  position: absolute;
  top: 90px;
`;

const PlayerCircle = ({name,live,isRoom,puuId,riot_id,riot_tag}) => {
    return (
        <Circle live={live}>
            <EventPlayer name={name} width={100} height={100} isRoom={isRoom} puuId={puuId} riot_id={riot_id} riot_tag={riot_tag}/>
            {live === 1 &&
            <LiveAbsolute>
                <img src={'images/event/event_live.svg'} width={42} height={20} alt={''}/>
            </LiveAbsolute>}
        </Circle>
    )
}

export default EventPlayerList;