import React, {useEffect, useState} from 'react';
import {Loading} from "../index";
import SummonerNotFound from "../../summoner/SummonerNotFound";
import Profile from "../../summoner/Profile";
import SummonerMatches from "../../summoner/Matches";
import Champ from "../../summoner/Champ";
import {SummonerContainer} from "../../../styled/SummonerStyle";
import Ingame from "../../summoner/Ingame";
import {useTranslation} from "react-i18next";
import {Route, Switch, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";
import {noticeChange} from "../../../store/modules/notice";
import styled from "styled-components";
import TabItem from "../TabItem";
import SummonerMastery from "../../summoner/Mastery";
import SummonerMatchSummary from "../../summoner/SummonerMatchSummary";
import AIScoreExplain from "../../summoner/AIScoreExplain";
import SummonerAdBanner from "../../ad/SummonerAdBanner";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 1080px;
`;



const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  z-index: 2;
`;

const FloatWrapper = styled.div`
  position: relative;
`;

const PentaKillWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;


const NewWrapper = styled.div`
  right: -22px;
  position: absolute;
`;


const BackGroundWrapper = styled.div`
  border-radius: 6px;
  padding: 16px;
  background-color: ${props => props.theme.color.site_background_color};
`;
const BannerBox = styled.div`
  margin-block: 10px;
`;


const SummonerContent = ({currentUserName}) => {
    const {userName, region, matchId} = useParams();
    const {t} = useTranslation();
    const location = useLocation();
    const currentLang = useSelector((state) => state?.translation.lang);
    const [backToNormal, setbackToNormal] = useState(false);
    const [queueNum, setQueueNum] = useState("ALL");
    const [bannerClicked, setBannerClicked] = useState(false);
    const currentSeason = useSelector((state) => state.imageStore.currentSeason);
    const [seasonSelected, setSeasonSelected] = useState(currentSeason ? currentSeason : 17);
    const oldSeason = useSelector((state) => state.imageStore.oldSeason);
    const mastery = useSelector((state) => state.summoner.mastery);
    const {path} = useRouteMatch();
    const {
        singleMatch,
        summoner,
        ingameStatus,
        getSummonerDone,
        getSummonerError
    } = useSelector((state) => state?.summoner);

    const currentPath = location.pathname.split('/')?.[4];

    useEffect(() => {
        window.scroll(0, 0);
    }, [userName]);

    useEffect(() => {
        if (getSummonerError) {

            if (getSummonerError !== 'No source') {
                noticeChange('alertError');
            }
        }
    }, [getSummonerError])


    const handleTab = (index) => {
        setbackToNormal(true);
    };


    if (getSummonerError) {
        return (
            <SummonerNotFound
                summonerName={userName}
            />
        )
    }
    if (!(getSummonerDone && oldSeason)) {
        return (
            <SummonerContainer>
                <Loading/>
            </SummonerContainer>
        )
    }


    return (

                    <BackGroundWrapper>
                        <ContentBox id={'summoner_content_box'}>
                            <Profile
                                selectedSeason={seasonSelected}
                                queueNum={queueNum}
                                handleTab={handleTab}
                                region={region}
                                userName={currentUserName}
                            />
                            <BannerBox>
                                <SummonerAdBanner status={summoner?.summoner_basic_info_dict?.pro_streamer_info_dict}/>
                            </BannerBox>
                            <div className={'main_tab'}>
                                <div className="main_tab_list">
                                    <TabItem to={`/summoner/${region}/${userName}`} selected={!currentPath}
                                             onClick={handleTab}>{t('tabs.summary')}</TabItem>
                                    <TabItem
                                        to={`/summoner/${region}/${userName}/champions`}
                                        selected={currentPath === 'champions'}>{t('tabs.champions')}</TabItem>
                                    {mastery?.length > 0 &&
                                        <TabItem to={`/summoner/${region}/${userName}/mastery`}
                                                 selected={currentPath === 'mastery'} onClick={handleTab}>
                                            <PentaKillWrapper>
                                                {t('mastery.otkBuild')}
                                                <NewWrapper>
                                                    <img
                                                        src={'/images/new_badge.svg'}
                                                        width={18}
                                                        height={18}
                                                        alt={""}
                                                    />
                                                </NewWrapper>
                                            </PentaKillWrapper>
                                        </TabItem>}
                                    <TabItem to={`/summoner/${region}/${userName}/ingame`}
                                             selected={currentPath === 'ingame'} onClick={handleTab}>
                                        <p>
                                            {t('tabs.liveGame')}
                                        </p>
                                        {ingameStatus ? (<span className="live"><img src="/images/ico_live.svg"
                                                                                     alt="LIVE"/></span>) : <></>}
                                    </TabItem>
                                    <AIScoreExplain/>
                                </div>
                                {/*{Array.isArray(pentaKill) && pentaKill.length > 0 ? <Tab>펜타킬</Tab> : null}*/}
                            </div>
                            <FloatWrapper>
                                {/*<Float/>*/}
                            </FloatWrapper>
                            <Switch>
                                <Route exact path={`${path}/mastery`}>
                                    <SummonerMastery/>
                                </Route>

                                <Route exact path={`${path}/ingame`}>
                                    <Ingame
                                        region={region}
                                    />
                                </Route>
                                <Route exact path={`${path}/champions`}>
                                    <Champ
                                        bannerClicked={bannerClicked}
                                        setBannerClicked={setBannerClicked}
                                        seasonSelected={seasonSelected}
                                        setSeasonSelected={setSeasonSelected}
                                    />
                                </Route>
                                <Route>
                                    {matchId && !backToNormal ? <SummonerMatches
                                            region={region}
                                            summoner={summoner}
                                            currentLang={currentLang}
                                            match={singleMatch}
                                        /> :
                                        <SummonerMatchSummary
                                            setSeasonSelected={setSeasonSelected}
                                            selectedSeason={seasonSelected}
                                            queueNum={queueNum}
                                            setQueueNum={setQueueNum}
                                            region={region}
                                        />
                                    }

                                </Route>
                            </Switch>
                        </ContentBox>
                    </BackGroundWrapper>

    );
};




export default React.memo(SummonerContent);