import React, {useState} from 'react';
import styled from "styled-components";
import ChampionsWhiteWrapper from "../../../Champions/ChampionsContent/ChampionsWhiteWrapper";
import AramBalanceHeader from "./AramBalanceHeader";
import {useParams} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import AramBalanceTable from "./AramBalanceTable";
import {useSelector} from "react-redux";


const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const FilterWrapper = styled.div`

  display: flex;
  flex-direction: row;
`;


const AramBalanceMain = ({list,version}) => {
    const currentLang = useSelector((state) => state.translation.lang);

    const {position} = useParams();
    const [choName, setChoName] = useState(false);
    const [search,setSearch] = useState("");



    return (
        <Wrapper>
            <AramBalanceHeader
                position={position}
                setInputValue={setSearch}
                inputValue={search}
                version={version}
                setChoName={setChoName}
            />
            <ChampionsWhiteWrapper>
                <ErrorBoundary fallback={<>Error</>} key={currentLang + "balanceError"}>
                    <AramBalanceTable
                        searchText={search}
                        choName={choName}
                        list={list}
                    />
                </ErrorBoundary>
            </ChampionsWhiteWrapper>
        </Wrapper>
    );
};

export default AramBalanceMain;
