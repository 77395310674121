import React, {useMemo, useState} from 'react';
import styled from "styled-components";
import {ColumnBox, FlexBox} from "../ui/styled";
import EventPlayer from "./EventIcon";
import EventDamageGraph from "./EventDamageGraph";
import {getDivisionValue, getTierValue, onStorePuuId, romanNumberConverter, sortChampData} from "../../function";
import {useTranslation} from "react-i18next";
import {
    AI_SCORE_BAD_TEXT,
    AI_SCORE_GOOD_TEXT,
    AI_SCORE_PERFECT_TEXT,
    AI_SCORE_VERY_BAD_TEXT
} from "../../styled/scoreColor";
import DailyRankingCrown from "../daily/content/DailyRankingCrown";
import ChampionImageWithLane from "../common/ChampionImageWithLane";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;
const AIScoreBox = styled.div`
  width: 300px;
  height: 640px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.site_main_color};
  overflow: hidden;
  flex-shrink: 0;
`;
const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.white_100};
  color: ${props => props.theme.color.dark_100};
  font-size: 18px;
  font-weight: bold;
`;
const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.color.site_main_color};
  width: 100%;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 10px;
`;
const InfoBox = styled.div`
  width: 380px;
  height: 315px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  background-color: ${props => props.theme.color.site_main_color};
  overflow: hidden;

  & + & {
    margin-left: 10px;
  }
`;
const EventInfo = ({list, aiList}) => {
    const {t} = useTranslation();
    const infoArray = useMemo(() => {
        try {
            const addInfoMap = list.map((data) => {
                const splitTier = data.tier.split(" ");
                const numberDivision = romanNumberConverter(splitTier[1])
                return {
                    rankWinRate: data.wins / (data.wins + data.losses) * 100,
                    tierValue: getTierValue(splitTier[0]) + getDivisionValue(numberDivision),
                    mostWinRate: data.most1_win / data.most1_cnt * 100,
                    ...data,
                }
            });
            return addInfoMap
        } catch (e) {
            console.log(e)
            return [];
        }
    }, [list]);
    const rankWinArray = useMemo(() => {
        try {
            return sortChampData(infoArray, [{value: "rankWinRate", sortType: false}]);
        } catch (e) {
            return [];
        }
    }, [infoArray]);
    const diamondProgress = useMemo(() => {
        try {
            const checkInfoArray = infoArray.map((data) => {
                return {
                    ...data,
                    tierValue: data.tierValue > 2500 ? 2500 : data.tierValue
                }
            })
            return sortChampData(checkInfoArray, [{value: "tierValue", sortType: false}]);
        } catch (e) {
            return [];
        }
    }, [infoArray]);
    const mostChamp = useMemo(() => {
        try {
            return sortChampData(infoArray, [{value: "mostWinRate", sortType: false}]);
        } catch (e) {
            return [];
        }
    }, [infoArray]);
    const liveTime = useMemo(() => {
        try {
            return sortChampData(infoArray, [{value: "total_time", sortType: false}]);
        } catch (e) {
            return [];
        }
    }, [infoArray]);
    const totalLiveTime = useMemo(() => {
        try {
            const timeArray = list.map((data) => {
                return data.total_time
            })
            return Math.max(...timeArray)
        } catch (e) {
            return 0;
        }
    }, [infoArray]);
    return (
        <Wrapper>
            <AIScoreBox>
                <ItemHeader>
                    {t('gemagema.aiRanking')}
                </ItemHeader>
                <ItemContent>
                    {aiList.map((data, index) => {
                        return (
                            <ScoreItem score={data.ai_score} rank={index + 1} champion_id={data.champion_id}
                                       name={data.riot_id} status={false} proName={""} platform={'kr'}
                                       tag={data.riot_tag} key={index}
                                       playerName={data.playerName}
                            />
                        )
                    })}
                </ItemContent>
            </AIScoreBox>
            <ColumnBox margin={'0 0 0 10px'}>
                <FlexBox>
                    <InfoItem title={t("gemagema.winRanking")} list={rankWinArray} damageObjName={'rankWinRate'}
                              total={100}/>
                    <InfoItem title={t("gemagema.diamond")} list={diamondProgress} damageObjName={'tierValue'}
                              total={2500}/>
                </FlexBox>
                <FlexBox margin={'10px 0 0 0'}>
                    <InfoItem title={t("gemagema.mostChamp")} list={mostChamp} damageObjName={'mostWinRate'}
                              total={100}/>
                    <InfoItem title={t("gemagema.liveTime")} list={liveTime} damageObjName={'total_time'}
                              total={totalLiveTime}/>
                </FlexBox>
            </ColumnBox>
        </Wrapper>
    );
};

const ScoreBox = styled.div`
  text-align: center;
  width: 50px;
  font-weight: bold;
  font-size: 14px;
  color: ${props => {
    let color = AI_SCORE_VERY_BAD_TEXT;
    if (props.score >= 75) {
      color = AI_SCORE_PERFECT_TEXT
    } else if (props.score >= 50) {
      color = AI_SCORE_GOOD_TEXT;
    } else if (props.score >= 25) {
      color = AI_SCORE_BAD_TEXT;
    }
    return color;
  }};
`;

const ScoreItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 59px;
  width: 100%;
`;
const CrownBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
`;
const ChampBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 220px;

  .imgDiv {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 2px solid ${props => {
      let color = 'transparent';
      if (props.rank === 3) color = '#BD9888';
      if (props.rank === 2) color = '#8E9EC4';
      if (props.rank === 1) color = '#FFA31A';
      return color
    }}
  }

  .imgChamp {
    width: 46px;
  }
`;
const ItemText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_100};
  margin-left: 5px;
  cursor: pointer;
  padding-bottom: 2px;

  &:hover {
    text-decoration: underline;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  min-width: 0;
`;
const ScoreItem = ({score, rank, champion_id, name, status, proName, platform, tag, playerName}) => {
    const [onHover, setOnHover] = useState(false);

    const handleMouseOver = () => {
        if (proName) {
            setOnHover(true);
        }
    };

    const handleMouseOut = () => {
        if (proName) {
            setOnHover(false);
        }
    }
    const onClickSummoner = () => {
        onStorePuuId(`${name}-${tag}`, platform)
    }

    return (
        <ScoreItemWrapper>
            <CrownBox>
                <DailyRankingCrown rank={rank} height={20} width={20}/>
            </CrownBox>
            <ChampBox rank={rank}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
            >
                <ChampionImageWithLane champion_id={champion_id}/>
                <ItemText onClick={onClickSummoner}>{playerName}</ItemText>
            </ChampBox>
            <ScoreBox score={score}>{Math.round(Number(score))?.toFixed(0)}</ScoreBox>
        </ScoreItemWrapper>
    )
}

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 20px;
`;
const InfoItem = ({title, list, damageObjName, total}) => {
    return (
        <InfoBox>
            <ItemHeader>
                {title}
            </ItemHeader>
            <InfoContent>
                {list.map((data, index) => {
                    return (
                        <InfoContentItem name={data.playerName} riot_tag={data.riot_tag} riot_id={data.riot_id}
                                         rank={index + 1} puuId={data.puu_id} key={data.summoner_id}
                                         dmg={data[damageObjName]} total={total}
                                         isChampion={damageObjName === 'mostWinRate'}
                                         champion_id={data.champion_id}
                                         isTime={damageObjName === 'total_time'}
                        />
                    )
                })}
            </InfoContent>
        </InfoBox>
    )
}

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .imgDiv {
    border-radius: 50%;
  }

  .imgChamp {
    width: 56px;
    height: 56px;
  }

  & + & {
    margin-top: 10px;
  }
`;
const GraphWrapper = styled.div`
  flex: 1;
  margin-left: 10px;
`;
const InfoContentItem = ({puuId, riot_id, riot_tag, name, rank, total, dmg, isChampion, champion_id, isTime}) => {
    if (isChampion) {
        return (<ItemWrapper>
            <ChampionImageWithLane champion_id={champion_id}/>
            <GraphWrapper>
                <EventDamageGraph dmg={dmg} total={total} name={name} rank={rank}/>
            </GraphWrapper>
        </ItemWrapper>)
    }
    return (
        <ItemWrapper>
            <EventPlayer isRoom={false} puuId={puuId} riot_id={riot_id} riot_tag={riot_tag} name={name} height={50}
                         width={50}/>
            <GraphWrapper>
                <EventDamageGraph dmg={dmg} total={total} name={name} rank={rank} isTime={isTime}/>
            </GraphWrapper>
        </ItemWrapper>
    )
}

export default EventInfo;