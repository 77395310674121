import React from 'react';
import {useTheme} from "styled-components";

const UseRuneFunc = () => {
    const theme = useTheme();
    const getRuneBorderColor = (runeId) => {
        switch (Number(runeId)) {
            case 8000:
                return '#FFCC54'
            case 8100:
                return '#E85C5C';
            case 8200:
                return '#8C85EB'
            case 8300:
                return '#6CBABC'
            case 8400:
                return "#6DD174"
        }
    }
    return {
        getRuneBorderColor
    }
};

export default UseRuneFunc;