import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  padding-left: 16px;
`;

const RankingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 2ch;
  height: 100%;
`;
const RankingText = styled.span`
  color: ${props => props.theme.color.dark_100};
  font-size: 12px;
  font-weight: 500;
`;

const ChangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left:${props => {
     let left = '12px';
     if(props.dictMargin) left = props.dictMargin;
     return left;
  }};
  height: 100%;
  width: 15px;
`;

const UpArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-left: 3.5px solid transparent;
  border-right: 3.5px solid transparent;
  border-bottom: 5px solid #1CA484;
`;

const DownArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid #E96767;
  border-left: 3.5px solid transparent;
  border-right: 3.5px solid transparent;
  border-bottom: 0 solid transparent;
`;

const UpDownText = styled.span`
  color: ${props => {
    let color = '#E96767';
    if (props.value > 0) color = '#1CA484';
    return color;
  }};
  font-size: 12px;
  margin-left: 4px;
`;
const Bar = styled.div`
  width: 10px;
  height: 1px;
  background-color: ${props => props.theme.color.dark_200};
`;

const NewText = styled.span`
  font-size: 16px;
  transform: scale(0.583);
  color: #528BEE;
`;

const CRTableRanking = ({value = 1, rank,rankColor ,dictMargin,newValue = 999}) => {
    const theme = useTheme();
    const renderDict = useCallback((rankDict) => {
        if (rankDict === 0) {
            return <Bar/>
        } else if (rankDict === newValue) {
            return <NewText>NEW</NewText>
        } else if (rankDict > 0) {
            return <UpArrow/>
        } else if (rankDict < 0) {
            return <DownArrow/>
        }
    }, [newValue]);
    return (
        <Wrapper>
            <RankingWrapper>
                <RankingText rankColor={rankColor || theme.color.background_5C6}>
                    {rank}
                </RankingText>
            </RankingWrapper>
            <ChangeWrapper dictMargin={dictMargin}>
                {typeof value === 'number' &&
                    <>
                        {renderDict(value)}
                        {value !== 0 && value !== newValue &&
                            <UpDownText value={value}>
                                {Math.abs(Math.trunc(value))}
                            </UpDownText>}
                    </>
                }
            </ChangeWrapper>
        </Wrapper>
    );
};

export default CRTableRanking;
