import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import variablePie from 'highcharts/modules/variable-pie.js';

variablePie(Highcharts);

const ChartWinrate = ({blueWinrate, redWinrate}) => {
    let blueY = blueWinrate;
    let redY = redWinrate;
    const blueZ = blueY > redY ? 10 : 1;
    const redZ = blueY < redY ? 10 : 1;

    const options = {
        chart: {
            type: 'variablepie',
            plotBackgroundColor: 'none',
            backgroundColor: 'none',
            width: 165,
            height: 165,
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        plotOptions: {
            variablepie: {
                dataLabels: {
                    enabled: false,
                },
            },
            series: {
                enableMouseTracking: false,
            },
        },
        series: [
            {
                type: 'variablepie',
                name: 'countries',
                minPointSize: 10,
                innerSize: '68%',
                center: [36, 36],
                zMin: 0,
                borderWidth: 0,
                turboThreshold: 100,
                data: [
                    {
                        name: 'red',
                        color: {
                            linearGradient: {x1: 0, x2: 1, y1: 0, y2: 1},
                            stops: [
                                [0, '#FFAFC2'],
                                [1, '#D92F56'],
                            ],
                        },
                        y: redY,
                        z: redZ,
                    },
                    {
                        name: 'blue',
                        color: {
                            linearGradient: {x1: 0, x2: 1, y1: 0, y2: 1},
                            stops: [
                                [0, '#ABCEFF'],
                                [1, '#4372B5'],
                            ],
                        },
                        y: blueY,
                        z: blueZ,
                    },
                ],
            },
        ],
        tooltip: {
            enabled: false,
        },
    };
    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}/>
        </>
    );
};

export default React.memo(ChartWinrate);
