import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled, {useTheme} from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getTierScore} from "../../../../function";
import LiveBlock from "./LiveBlock";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexBox = styled.div`
  padding: 15px;
  background-color: ${props => props.theme.color.site_main_color};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;


const BasicInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RankText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  height: 24px;
`;

const MapText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
  margin-left: 10px;
`;


const Table = styled.table`
  flex: 1;
  border-left: 1px solid ${props => props.theme.color.default_border_color};
`;

const HeaderTr = styled.tr`
  height: 32px;
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;


const HeaderTh = styled.th`
  font-size: 12px;
  position: relative;
  vertical-align: middle;
  color: ${props => props.theme.color.dark_200};
`;

const LoadingBox = styled.div`
  position: absolute;
  right: 0;
  left: 100px;
  top: 28px;
  bottom: 0;
`;

const RightBar = styled.div`
  position: absolute;
  right: 0;
  top: 11px;
  height: 9px;
  background-color: ${props => props.theme.color.default_border_color};
  width: 1px;
`;

const ShareWrapper = styled.div`
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${props => {
    let border = `1px solid ${props.theme.color.default_border_color}`
    if (props.theme.color.theme === 'dark') {
      border = 0;
    }
    return border;
  }};
  background-color: ${props => props.theme.color.site_main_color};
  margin-left: 9px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.color.white_200};
  }
`;

const ShareImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

`;

const ShareText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.color.dark_200};
  margin-top: 4.5px;
`;

const CopyText = styled.span`
  position: absolute;
  top: 10px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  color: #fff;
  transform: translateX(-50%);
  z-index: 23;
  font-size: 12px;
  font-weight: 500;
`;


const BanBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  background-color: ${props => props.bgColor};

  & + & {
    margin-left: 10px;
  }
`;

const BanTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.dark_200};
  margin: ${props => props.margin || 0};
`;

const CenterItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EmptyBan = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 32px;
  height: 32px;
`;
const BanChampionBox = styled.div`
  position: relative;

  & + & {
    margin-left: 5px;
  }
`;
const BanWrapper = styled.div`
  display: flex;
  flex-direction: row;

  .imgDiv {
    width: 32px;
    height: 32px;
    border-radius: 6px;
  }

  .imgChamp {
    width: 34px;
    height: 34px;
  }

  .imgDiv {
    border-radius: 4px;
    border: 2px solid ${props => props.borderColor};
  }


  .non_image {
    width: 32px;
    height: 32px;
  }
`;

const BanSign = styled.div`
  position: absolute;
  width: 2px;
  height: 32px;
  background-color: #fff;
  transform: rotate(45deg);
  top: 0;
  right: 15px;
`;



const IngameOverview = () => {
    const theme = useTheme();
    const [copy, setCopy] = useState(false);
    const {ingame, getIngameInfoLoading} = useSelector((state) => state.summoner);
    const {t, i18n} = useTranslation();
    const onClickShare = useCallback(() => {
        setCopy(true);
        const copyText = `${window.location.href}`;
        navigator.clipboard.writeText(copyText);
    }, []);

    useEffect(() => {
        if (copy) {
            let timer = setTimeout(() => {
                setCopy(false)
            }, 2000);
            return () => {
                clearTimeout(timer)
            }
        }
    }, [copy])


    const avgWinRate = useMemo(() => {
        try {
            const blueWinRate = ingame.model.blue_win_rate;
            return {
                blue: blueWinRate,
                red: ingame.model.red_win_rate,
            }
        } catch (e) {
            return {
                blue: 0,
                red: 0,
            }
        }

    }, [ingame]);

    const avgAiScore = useMemo(() => {
        try {
            const part = ingame.part.reduce((sum, cur) => {

                sum.blue += Number(cur.blue.summoner_info_dict.ai_score_avg);
                sum.red += Number(cur.red.summoner_info_dict.ai_score_avg);
                return sum;
            }, {blue: 0, red: 0});
            return {
                blue: part.blue / ingame.part.length,
                red: part.red / ingame.part.length,
            }
        } catch (e) {
            console.log(e);
            return {
                blue: 0,
                red: 0,
            }
        }
    }, [ingame]);


    const getAvgTier = useCallback((playerList) => {
        const filterUnrank = playerList.filter((data) => data.tier !== "unranked");
        const combinedPlayerTier = filterUnrank.reduce((sum, cur) => {
            sum += getTierScore(`${cur.tier} ${cur.division}`).num
            return sum;
        }, 0);
        let length = filterUnrank.length;
        if (filterUnrank.length === 0) {
            length = 1;
        }
        return getTierScore(Math.trunc(combinedPlayerTier / length)).obj;
    }, []);

    const avgTier = useMemo(() => {
        try {

            const blueTierMap = ingame.blueTeam.map((data) => data.season_tier_info_dict.ranked_solo_5x5);
            const redTierMap = ingame.redTeam.map((data) => data.season_tier_info_dict.ranked_solo_5x5);

            const blueAvgTier = getAvgTier(blueTierMap);
            const redAvgTier = getAvgTier(redTierMap);

            return {
                blue: blueAvgTier,
                red: redAvgTier
            }
        } catch (e) {
            console.log("avgTier", e);
            return {
                blue: {tier: "Unknown", divisiqon: 1},
                red: {tier: "Unknown", divisiqon: 1}
            }
        }
    }, [ingame]);


    const queueText = useMemo(() => {
        try {
            const queueId = ingame.basic.queue_id;
            switch (queueId) {
                case 0:
                    return t('summoner.customGame')
                case 420:
                    return t('summoner.rankedSolo');
                case 440:
                    return t('summoner.rankedFlex');
                case 450:
                    return t('summoner.random')
                default:
                    return t('summoner.blind');
            }
        } catch (e) {
            console.log(e);
            return t('summoner.blind');
        }
    }, [ingame.basic.queue_id, i18n.language])


    const mapText = useMemo(() => {
        try {
            const queueId = ingame.basic.queue_id;
            if(queueId === 0) {
                if(ingame.basic.map === 12) {
                    return t('ingame.AramMapName')
                } else {
                    return t('ingame.mapName');
                }
            }
            switch (queueId) {
                case 420:
                    return t('ingame.mapName');
                case 440:
                    return t('ingame.mapName');
                case 450:
                    return t('ingame.AramMapName')
                default:
                    return t('ingame.mapName');
            }
        } catch (e) {
            console.log(e);
            return t('ingame.mapName');
        }
    }, [ingame.basic.queue_id, i18n.language])


    return (<Wrapper>
        <FlexBox>
            <BasicInfoBox>
                <LiveBlock
                    initTimer={ingame.basic.start_timestamp}
                />
                <RankText>{queueText}<MapText>{mapText}</MapText>
                </RankText>
            </BasicInfoBox>
            <CenterItem>
                <BanBox bgColor={theme.color.blue_300}>
                    <BanWrapper borderColor={theme.color.blue_100}>
                        {ingame.basic.ban_id_dict.blue.map((data, index) => {
                            if(data < 1) {
                                return (
                                    <BanChampionBox key={index}>
                                        <EmptyBan className={'imgDiv'}>
                                            <BanSign/>
                                        </EmptyBan>
                                    </BanChampionBox>
                                )
                            }
                            return (
                                <BanChampionBox key={index}>
                                    <ChampionImageWithLane
                                        banSign={true}
                                        key={data}
                                        borderRadius={0}
                                        champion_id={data}
                                    />
                                </BanChampionBox>
                            )
                        })}
                    </BanWrapper>
                </BanBox>

                <BanBox bgColor={theme.color.red_400}>
                    <BanWrapper borderColor={theme.color.red_100}>
                        {ingame.basic.ban_id_dict.red.map((data, index) => {
                            if(data < 1) {
                                return (
                                    <BanChampionBox key={index}>
                                        <EmptyBan className={'imgDiv'}>
                                            <BanSign/>
                                        </EmptyBan>
                                    </BanChampionBox>
                                )
                            }
                            return (
                                <BanChampionBox key={index}>
                                    <ChampionImageWithLane
                                        banSign={true}
                                        key={data}
                                        borderRadius={0}
                                        champion_id={data}
                                    />
                                </BanChampionBox>
                            )
                        })}
                    </BanWrapper>

                </BanBox>
            </CenterItem>
            {/*<Table>*/}
            {/*    <colgroup>*/}
            {/*        <col/>*/}
            {/*        <col width={'8%'}/>*/}
            {/*        <col width={'10%'}/>*/}
            {/*        <col width={'20%'}/>*/}
            {/*    </colgroup>*/}
            {/*    <thead>*/}
            {/*    <HeaderTr>*/}
            {/*        <HeaderTh>{t("ingame.winPrediction")}{getIngameInfoLoading &&*/}
            {/*            <LoadingBox><Loading size={'4px'}/></LoadingBox>}</HeaderTh>*/}
            {/*        <HeaderTh>{t("ingame.avgTier")}</HeaderTh>*/}
            {/*        <HeaderTh>{t("ingame.avgAiScore")}</HeaderTh>*/}
            {/*        <HeaderTh>{t("ingame.ban")}</HeaderTh>*/}
            {/*    </HeaderTr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    <OverviewTableTr*/}
            {/*        teamName={t('ingame.blueTeam')}*/}
            {/*        borderBottom={true}*/}
            {/*        teamColor={theme.color.blue_100}*/}
            {/*        winRate={avgWinRate.blue}*/}
            {/*        aiScore={avgAiScore.blue}*/}
            {/*        banList={ingame.basic.ban_id_dict.blue}*/}
            {/*        tier={avgTier.blue}*/}
            {/*    />*/}
            {/*    <OverviewTableTr*/}
            {/*        teamName={t('ingame.redTeam')}*/}
            {/*        teamColor={theme.color.red_100}*/}
            {/*        winRate={avgWinRate.red}*/}
            {/*        aiScore={avgAiScore.red}*/}
            {/*        banList={ingame.basic.ban_id_dict.red}*/}
            {/*        tier={avgTier.red}*/}
            {/*    />*/}
            {/*    </tbody>*/}
            {/*</Table>*/}
        </FlexBox>
        {/*<ShareWrapper onClick={onClickShare}>*/}
        {/*    <ShareImageWrapper>*/}
        {/*        <img src={`${theme.icon.path}/ico_copy_link.svg`} width={21} height={21} alt={""}/>*/}
        {/*        <ShareText>{t('buttons.share')}</ShareText>*/}
        {/*        {copy && <CopyText className="share_tooltip">{t('multi.clipboard')}</CopyText>}*/}
        {/*    </ShareImageWrapper>*/}

        {/*</ShareWrapper>*/}
    </Wrapper>);
};

export default React.memo(IngameOverview);
