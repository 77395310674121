import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import ListButton from "../../../common/ListButton";

const TypeWrapper = styled.div`
  display: flex;
  border-color: ${props => props.theme.color.default_border_color};
  min-width: 110px;
  margin-left: 10px;
  white-space: nowrap;
  height: 24px;
`;

const RenderDiv = styled.button`
  width: 100%;
  display: flex;
  flex: 1;
  padding: 3px 4px;
  flex-direction: row;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => {
    let color = props.theme.color.default_border_color;
    if (props.selected) color = '#353063';
    return color;
  }};
  background-color: ${props => {
    let color = props.theme.color.background_FOE;
    if (props.selected) color = props.theme.color.background_5F;
    return color;
  }};
`;

const RenderButton = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: ${props => {
    let color = props.theme.color.dark_100;
    if (props.selected) color = '#FFFFFF';
    return color;
  }};
  opacity: ${props => {
    let opacity = '0.5';
    if (props.selected) opacity = '1';
    return opacity;
  }};
`;

const MultiListType = ({onClickButton, renderType}) => {
    const {t} = useTranslation();
    return (
        <>
            <TypeWrapper>
                <ListButton
                    selected={renderType === 0}
                    itemLength={2}
                    index={0}
                    selectedIndex={renderType}
                    width={'65px'}
                    onClick={() => onClickButton(0)}
                    fontSize={'12px'}
                >
                    {t('multi.type.aiScore')}
                </ListButton>
                <ListButton
                    selected={renderType === 1}
                    itemLength={2}
                    index={1}
                    selectedIndex={renderType}
                    width={'65px'}
                    onClick={() => onClickButton(1)}
                    fontSize={'12px'}
                >
                    {t('multi.type.KDA')}
                </ListButton>
                {/*<RenderDiv*/}
                {/*    onClick={() => onClickButton(0)}*/}
                {/*    selected={renderType === 0}>*/}
                {/*    <RenderButton*/}
                {/*        selected={renderType === 0}*/}
                {/*    ></RenderButton>*/}
                {/*</RenderDiv>*/}
                {/*<RenderDiv*/}
                {/*    onClick={() => onClickButton(1)}*/}
                {/*    selected={renderType === 1}>*/}
                {/*    <RenderButton*/}
                {/*        selected={renderType === 1}>{t('multi.type.KDA')}</RenderButton>*/}
                {/*</RenderDiv>*/}
            </TypeWrapper>
        </>
    );
};

export default MultiListType;