import React, {Suspense} from 'react';
import DailyHeader from "./DailyHeader";
import DailyFilter from "./DailyFilter";
import styled from "styled-components";
import DailyRankingList from "./DailyRankingList";
import {useParams} from "react-router-dom";
import DailyContentTable from "./DailyContentTable";


const Wrapper = styled.div`
  width: 100%;
`;

const DailyContent = () => {
    const {tier} = useParams();
    return (
        <Wrapper>
            <DailyHeader/>
            <DailyFilter/>
            <Suspense fallback={<></>}>
                {tier === 'all' ? <DailyRankingList/> : <DailyContentTable/>}
            </Suspense>
        </Wrapper>
    );
};

export default DailyContent;